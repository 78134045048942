<template>
  <div class="boxed-container">
    <nav class="navbar horizontal-menu navbar-fixed-top">
      <div class="navbar-inner">
        <div class="navbar-brand">
          <a href="/" class="logo">
            <img src="../assets/images/logo_dark@2x.png" width="100%" alt="" class="hidden-xs">
            <img src="../assets/images/logo@2x.png" width="100%" alt="" class="visible-xs">
          </a>
        </div>
        <div class="navbar-mobile-clear"></div>
      </div>
    </nav>

    <div class="page-container">
      <div class="main-content" style="">
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default" style="margin-top: 1px">
            <div class="row">
              <div class="col-sm-4">
                <img class="imgs" src="../assets/images/2x.png">
              </div>
              <div class="col-sm-8">
                <br />
                <br />
                <h4 class="text-gray">关于本站</h4>
                <br />
                <br />
                <br />
                <p>生命链是国内领先的“B2B2C”公共急救品牌；率先发布了适合全球华人和
                  亚太人种的公共急救课程体系及综合服务平台。</p>
                <br />
                <p>生命链是国内领先的“B2B2C”公共急救品牌；率先发布了适合全球华人和
                  亚太人种的公共急救课程体系及综合服务平台。</p>

                <p>目前，生命链导师及服务点已遍布全国各大主流城市，为超过1000+机构、
                  10W+人群提供了高效优质的公共急救服务，已成为国内首创公共急救领域
                  创就业互联网云平台。</p>
              </div>
            </div>
            </div>
            <div class="panel panel-default">
              <!-- 关于网站 -->
<!--              <h4 class="text-gray">关于网站</h4>-->
              <div class="panel-body">

                <!-- 关于站长 -->

                <div class="row">
                  <div class="col-sm-6">
                    <h4 class="text-gray">关注我们</h4>
                    <br />
                    <p>这是一个公益项目，而且是开源的。你也可以拿来制作自己的网址导航。
                      如果你有更好的想法，可以通过企业网站samery.cn中的联系方式找到我，
                      欢迎来电交流分享。</p>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-sm-4">
                          <img class="qr" src="../assets/images/qr.png">
                          <p class="text-center">生命链小程序</p>
                        </div>
                        <div class="col-sm-4">
                          <img class="qr" src="../assets/images/map.png">
                          <p class="text-center">城市救命地图小程序</p>
                        </div>
                        <div class="col-sm-4">
                          <img class="qr" src="../assets/images/gzh.png">
                          <p class="text-center">生命链急救公众号</p>
                        </div>
                      </div>
                    </div>
                    <br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  components: {
  },
  data() {
    return {
      authorImg: './assets/images/logos/viggoz.png'
    }
  }
}
</script>

<style>
.imgs{
  width:100%;
  /*max-width: 312px;*/
  /*position: absolute;*/
  /*top: -200px;*/
}
.qr{
  width: 100%;
  margin: 10px 0;
}
/* 小屏幕中图片宽度为 100% */
/*@media only screen and (max-width: 800px){*/
/*  .imgs {*/
/*    top: 0px;*/
/*  }*/
/*}*/
/*!* 小屏幕中图片宽度为 100% *!*/
/*@media only screen and (max-width: 700px){*/
/*  .imgs {*/
/*    width: 90%;*/
/*    top: -300px;*/
/*  }*/
/*  .qr{*/
/*    width: 100%;*/
/*  }*/
/*}*/
</style>
