<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        <a href="https://beian.miit.gov.cn/" target="black">粤ICP备19041647号 
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44011102483976" rel="noreferrer" target="_blank"> 粤公网安备44011102483976号 </a></a> ©2015-2019 广州生命链健康信息有限公司
      </div>
      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
footer.main-footer{
  background: #F7F9FC !important;
}
</style>
