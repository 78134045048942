<template>
  <div class="boxed-container">
    <nav class="navbar horizontal-menu navbar-fixed-top">
      <div class="navbar-inner">
        <div class="navbar-brand">
          <a href="/" class="logo">
            <img src="../assets/images/logo_dark@2x.png" width="100%" alt="" class="hidden-xs">
            <img src="../assets/images/logo@2x.png" width="100%" alt="" class="visible-xs">
          </a>
        </div>
        <div class="navbar-mobile-clear"></div>
      </div>
    </nav>

    <div class="page-container">
      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default">
              <div class="row">
                <div class="panel-heading"> <h3 class="panel-title">我要投稿</h3>  </div>
                <br>
                <div class="form-horizontal">
                  <div class="form-group">
                    <label class="col-sm-2 control-label">
                      站点名称
                    </label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" v-model="title"  maxlength="15"  placeholder="请输入站点名称">
                    </div>
                  </div>
                  <div class="form-group"><label class="col-sm-2 control-label">所属类别</label>
                    <div class="col-sm-10">
                      <select  class="form-control" v-model="categoryId"  @change="changeProduct($event)" >
                        <option v-for="(item,index) in categoryList" :key="index" :value='item.id'>{{item.title}}</option>
                      </select>
                     </div>
                  </div>
                  <div class="form-group">
                    <label class="col-sm-2 control-label">
                      上传LOGO
                    </label>
                    <div class="col-sm-10">
                      <input type="file" class="form-control" @change="logoFile">
                    </div>
                  </div>
<!--                  <div class="form-group"><label class="col-sm-2 control-label">跳转方式</label>-->
<!--                    <div class="col-sm-10">-->
<!--                      <p><label class="radio-inline">-->
<!--                        <input type="radio" name="radio-2" value="1" v-model="urlType"  >-->
<!--                        链接跳转-->
<!--                      </label>-->
<!--                        <label class="radio-inline">-->
<!--                          <input type="radio" name="radio-2" value="2" v-model="urlType">-->
<!--                        扫码打开-->
<!--                      </label>-->
<!--                      </p>-->
<!--                    </div>-->
<!--                  </div>-->

                  <div class="form-group" v-show="urlType == 1">
                    <label class="col-sm-2 control-label" >
                      跳转链接
                    </label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control"  v-model="urlString" placeholder="URL">
                    </div>
                  </div>
                  <div class="form-group" v-show="urlType == 2">
                    <label class="col-sm-2 control-label" >
                      上传二维码
                    </label>
                    <div class="col-sm-10">
                      <input type="file" class="form-control" @change="qrFile">
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-sm-2 control-label">站点介绍</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" maxlength="40" v-model="introduce" placeholder="请输入站点介绍..." cols="6"></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <br>
                    <label class="col-sm-2 control-label"></label>
                    <div class="col-sm-10">
                      <button class="btn btn-red" @click="tijiao">提交</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12" style="text-align: center">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-2">
        <img class="qr" v-if="logo_show" :src="logo_show">
        <p v-if="logo_show">上传的logo</p>
      </div>
      <div class="col-sm-2">
        <img class="qr" v-if="qr_show" :src="qr_show">
        <p v-if="qr_show">上传的二维码</p>
      </div>
      <div class="col-sm-4">
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contribute",
  data() {
    return {
      logo_show:'',
      qr_show:'',
      title:'',
      categoryId:1,
      logo:'',    //LOGO
      urlType:1,    //跳转方式
      urlString:"",    //跳转文字链接
      urlImage:'',    //跳转图片
      introduce:"" ,
      categoryList: []
    }
  },
  created() {
    axios.get('https://pro.samery.cn/aid/index/getApi?apiJson=' + JSON.stringify({ 'n': 'categoryList', 't': 'ordevqbrt6cn7dgs1s94rlke1m'}))
        .then(res => {
          this.categoryList =res.data.list
          this.categoryId = res.data.list[0].id
          this.urlType = res.data.list[0].urlType
        })
  },
  methods: {
    tijiao(){
      const title = this.title
      const categoryId = this.categoryId
      const logo = this.logo
      const urlType = this.urlType
      const urlString = this.urlString
      const urlImage = this.urlImage
      const introduce = this.introduce
      const w = {
        'title': title,
        'categoryId': categoryId,
        'logo': logo,
        'urlType': urlType,
        'urlString': urlString,
        'urlImage': urlImage,
        'introduce': introduce
      }
      if(title.length < 1  || logo=='' || introduce.length < 1) {
        alert('请输入完整信息')
      }else if (this.urlType==1 && !this.isUrl(urlString)){
        alert('请输入正确url地址')
      }else {
        axios.get('https://pro.samery.cn/aid/index/getApi?apiJson=' + JSON.stringify({ 'n': 'siteSubmit', 't': 'ordevqbrt6cn7dgs1s94rlke1m',  'w': w}))
            .then(res => {
              if (res.data.s == 200){
                alert(res.data.d)
                this.$router.push('/')
              }else {
                alert(res.data.d)
              }
            })
      }
    },
    isUrl (url) {
    return /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(url)
    },
qrFile(event) {
      let  type = 2
      this.uploadFile(type,event.target.files[0]);
    },
    logoFile(event) {
      let  type = 1
      this.uploadFile(type,event.target.files[0]);
    },
    uploadFile(type,files){
      let zipFormData = new FormData();
      let that = this
      zipFormData.append('imageFile', files);//filename是键，file是值，就是要传的文件
      let config = { headers: { 'Content-Type': 'multipart/form-data' } };
      axios.post('https://pro.samery.cn/admin/upload_file/imageSingle', zipFormData,config).then(function (response) {
        if (type === 1){
          that.logo =response.data.data.id
          that.logo_show =response.data.data.path
        }else if (type === 2){
          that.urlImage =response.data.data.id
          that.qr_show =response.data.data.path
        }
      });
    },
    changeProduct(event) {
      this.categoryId = event.target.value;
        let result = this.categoryList.find((item) => {
            return item.id == this.categoryId
        })
      this.urlType = result.urlType;
    },
  }
}
</script>

<style scoped>
.qr{
  width: 100px;
  height: 100px;
}
</style>
