<template>
  <div class="page-container">
    <div class="sidebar-menu toggle-others fixed">
      <div class="sidebar-menu-inner">
        <header class="logo-env">
          <!-- logo -->
          <div class="logo">
            <a href="#" class="logo-expanded">
              <img src="../assets/images/logo@2x.png" width="100%" alt="" />
            </a>
            <a href="#" class="logo-collapsed">
              <img src="../assets/images/logo-collapsed@2x.png" width="40" alt="" />
            </a>
          </div>
          <div class="mobile-menu-toggle visible-xs">
            <a href="#" data-toggle="user-info-menu">
              <i class="linecons-cog"></i>
            </a>
            <a href="#" data-toggle="mobile-menu">
              <i class="fa-bars"></i>
            </a>
          </div>
        </header>
        <!-- 侧边栏 -->
        <ul id="main-menu" class="main-menu">
          <li v-for="(menu, idx) in items" :key="idx">
            <a :href="'#'+transName(menu)" class="smooth" @click="moveSlow">
<!--              <i class="fa" :class="menu.icon"></i>-->
              <img class="icos" :src="menu.icon"/>
              <span class="title">{{transName(menu)}}</span>
            </a>
            <ul v-if="menu.children">
              <li v-for="(submenu, idx) in menu.children" :key="idx">
                <a :href="'#'+transName(submenu)" class="smooth" @click="moveSlow">
                  <span class="title">{{transName(submenu)}}</span>
<!--                  <span v-show="submenu.is_hot" class="label label-pink pull-right hidden-collapsed">Hot</span>-->
                </a>
              </li>
            </ul>
          </li>
          <!-- 关于本站 -->
          <li class="submit-tag">
            <router-link to="/contribute">
              <i class="fa-file-text-o"></i>
              <span class="tooltip-blue">我要投稿</span>
            </router-link>
          </li>
          <!-- 关于本站 -->
          <li class="submit-tag">
            <router-link to="/about">
              <i class="linecons-heart"></i>
              <span class="tooltip-blue">关于本站</span>
<!--              <span class="label label-Primary pull-right hidden-collapsed">♥︎</span>-->
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-content">
      <nav class="navbar user-info-navbar" role="navigation">
        <ul class="user-info-menu left-links list-inline list-unstyled">
          <li class="hidden-sm hidden-xs">
            <a href="#" data-toggle="sidebar"><i class="fa-bars"></i></a>
          </li>
          <li class="dropdown language-switcher">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              分享最酷的健康导航和急救资源
            </a>
          </li>
        </ul>
        <ul class="user-info-menu right-links list-inline list-unstyled">
          <li class="hidden-sm hidden-xs">
            <a>
              Ctrl+D收藏导航
            </a>
          </li>
          <li class="hidden-sm hidden-xs">
            <a href="http://www.samery.cn/" target="_blank">
              <i class="fa fa-desktop"></i>  官网
            </a>
          </li>
        </ul>
      </nav>
      <div v-for="(item, idx) in items" :key="idx">
        <div v-if="item.web">
          <WebItem @func="getMsgFormSon" :item="item" :transName="transName" />
        </div>
        <div v-else v-for="(subItem, idx) in item.children" :key="idx">
          <WebItem :item="subItem" :transName="transName" />
        </div>
      </div>
      <Footer />
    </div>
    <!-- 弹窗 -->
    <div  class="modal2" :style="{display: displays}" @click="displayh">
      <!-- 弹窗内容 -->
      <img class="modal-content" :src="img">
      <!-- 文本描述 -->
      <div id="caption">微信扫一扫</div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import WebItem from '../components/WebItem.vue'
import Footer from '../components/Footer.vue'
import itemsData from '../assets/data.json'

export default {
  name: 'Index',
  components: {
    WebItem,
    Footer
  },
  data() {
    return {
      items: itemsData,
      displays:'none',
      img:'http://wed.samery.cn/img/qr.jpg'
    }
  },
  created() {
    axios.get('https://pro.samery.cn/aid/index/getApi?apiJson=' + JSON.stringify({ 'n': 'categoryList', 't': 'ordevqbrt6cn7dgs1s94rlke1m'}))
        .then(res => {
          if (res.data.s==200){
            this.items = res.data.list
          }
        })
  },
  methods: {
    getMsgFormSon(data){
      if (data.urlType === 2){
        this.displays = 'block'
        this.img = data.urlImage
      }else {
        window.open(data.urlString, '_blank');
      }
    },
    displayh(){
      this.displays = 'none'
    },
    transName(webItem) {
      return webItem.title;
    },
    moveSlow() {
    }
  }
}
</script>

<style>
.icos{
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
/* 弹窗背景 */
.modal2 {
  /*display: none; !* Hidden by default *!*/
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 200px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
}

/* 图片 */
.modal-content {
  margin: auto;
  display: block;
  max-width: 200px;
  background: #FFFFFF;
}

/* 文本内容 */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* 添加动画 */
.modal-content, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* 小屏幕中图片宽度为 100% */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
</style>
